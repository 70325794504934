import React from 'react';

export default function Loading() {
    const text = "Chargement en cours...";

    return (
        <div className="w-full h-screen cursor-wait mt-4">
            <div className="mx-auto text-center">
                <div className="loader">
                    <img
                        src="https://ozlaloc.fr/wp-content/uploads/2021/05/cropped-logo-150x150-1-192x192.png"
                        className="h-44 w-44 mx-auto"
                        alt="Logo"
                    />
                </div>
                <p className="text-2xl justify-center">
                    {text}
                </p>
            </div>
        </div>
    );
}
